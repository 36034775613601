@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: poppins;
  src: url("./Assets/fonts/poppins-light-webfont.woff2") format('woff2');
  font-display: swap;
}

.poppins {
  font-family: poppins;
}
*{
  font-family: "Poppins" !important;
}

.plans button {
  background-color: #b28759;
  border-radius: 10px;
}

.bg-blue {
  background-color: #456494;
}

.border-blue {
  border: 3px solid #456494;
}
.details-btn {
  background-color: #b28759;
  color: #fff;
  padding: 10px 20px !important;
  font-family: "Poppins", sans-serif !important;
  border: none !important;
  /* display: flex !important; */
  width: 135px !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-section {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.details-section.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.br-black {
  border: 2px solid black;
}
.position-absolute-important {
  position: absolute !important;
}
@media (max-width: 600px) {
  .add-ons-list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }

  .add-ons-list li {
    width: 100%;
  }

  .radio-btns {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .radio-btns li {
    width: 100%;
  }

  .plan-detail-header h2 {
    font-size: 22px;
  }

  .plan-detail-header {
    flex-wrap: wrap;
  }
}








input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
transform: scale(1.2);
} 

input[type="radio"]:checked + label span
{
background-color: #0D1E32;
box-shadow: 0px 0px 0px 4px white inset;

}

input[type="radio"]:checked + label{
 color: #0D1E32;
}

