.overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 100px); /* Subtracting 100px from the total height */
    z-index: 999;
    top: 100px; /* 100px space from the top */
    left: 0;
    background-color: rgba(255, 255, 255, 0.6); 
    display: block;
    backdrop-filter: blur(2.5px); 
    -webkit-backdrop-filter: blur(2.5px); 
    pointer-events: none;
}
.overlay.hidden {
    opacity: 0.4;
}

.disable-background {
    pointer-events: none;  /* Disable clicks, inputs, and interactions */
}