/* body{
    background-image: url("./../../Assets/Images/backgroundimage.png");
    background-position: 0 0;
    background-size: auto;
    height: 100vh;
} */

/* Container styling */
.form-container {
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 1; /* Initially visible */
}
/* i remove padding from right and reduce to 10px */

/* Header styling */
.header-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Input and label styling */
.customer-form label,
.card-form label {
  display: block;
  margin-bottom: 10px;
}
.customer-form {
  border-radius: 9px !important;
  padding: 30px;
  margin-bottom: 20px;
}

.customer-form input,
.card-form input {
  width: 100%;
  padding: 10px;
  margin-top: 5px !important;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button styling */
.stripe-button {
  background-color: #b28759;
  color: #fff;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  border: none;
  letter-spacing: 0.1px;
  border-radius: 5px;
  width: 164px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.pay-form {
  margin: 10px 0px;
}
.stripe-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.stripe-button:hover {
  background-color: rgb(50, 52, 58);
}

/* Add animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade in animation for the card form */
.card-form {
  animation: fadeIn 0.5s ease-in-out;
  margin-top: 20px;
}

/* Slide in animation for the form container */
.form-container.slide-in {
  animation: slideIn 0.5s ease-in-out;
}

/* Slide in animation keyframes */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 3200px) {
  .container {
    max-width: unset !important;
  }
}

@media (max-width: 450px) {
  .pay-form {
    margin: 0;
  }
  .customer-form {
    border-radius: 9px !important;
    padding: 30px;
    margin-bottom: 20px;
    /* margin: 20px 10px; */
  }
}
.home-container {
  text-align: center;
  display: flex;

  justify-content: center;
  align-items: center;
  margin-top: 250px;
  font-size: 30px;
  flex-direction: column;
}
.card-box-custom {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/* .checkout-box-custom  {
    border: 2px solid black;
   
 } */

 .iframe-button {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
  letter-spacing: 0.5px;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}




.pay-button {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b28759 !important;
  color: #fff;
  padding: 10px 20px;
  letter-spacing: 0.5px;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  width: 104px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.pay-button:hover {
  background-color: rgb(50, 52, 58);
}
.checkout-box-custom-button-box {
  justify-content: space-around;
}

@media (max-width: 450px) {
  .checkout-box-custom-button-box {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .checkout-box-custom {
    width: 50%;
    margin: auto;
  }
}

.successfull-box-custom svg {
  font-size: 10rem;
  margin: auto;
  color: lightgreen;
}
.successfull-box-custom {
  margin-top: 30px;
}
.successfull-box-custom h1 {
  margin: 10px 0px;
  font-weight: 600;
  margin-top: 0;
}
.stepper-width {
  margin-top: 30px;
}

.successfull-box-custom h6 {
  margin: 10px 0px;
  font-weight: 500;
  margin-top: 0;
}
@media (max-width: 425px) {
  .bottom-content {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 3200px) {
  .stepper-width {
    width: 60%;
    margin: auto;
  }
}
@media (min-width: 320px) and (max-width: 799px) {
  .stepper-width {
    width: 80%;
    margin: auto;
  }
}
.card-custom-body {
  height: auto;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
